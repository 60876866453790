import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Detail from '../views/Detail.vue'
import ueModel from '../views/ueModel.vue'
import HomeViewWf from '@/views/HomeViewWf.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    // children: [{
    //   path: '/detail',
    //   name: 'detail',
    //   component: () => {
    //     return import("../views/Detail.vue")
    //   }
    // }]
  },
  {
    path:'/ueModel/:id/:name/:sensor/:table',
    name:'ueModel',
    component: ueModel,
  },
  {
    path:'/detail',
    name:'detail',
    component: Detail,
  },
  {
    path: '/VRModel',
    name: 'VRModel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/vrModel.vue')
  },
  {
    path: '/HomeViewWf',
    name: 'HomeViewWf',
    component: HomeViewWf,
    // children: [{
    //   path: '/detail',
    //   name: 'detail',
    //   component: () => {
    //     return import("../views/Detail.vue")
    //   }
    // }]
  },
]

const router = new VueRouter({
  routes
})

export default router
