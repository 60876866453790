<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default{
  mounted() {
    // window.addEventListener('resize',this.handleResize)
    // this.handleResize()
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    // handleResize() {
    //    // 获取窗口的宽度和高度
    //    const windowWidth = window.innerWidth || document.documentElement.clientWidth;
    //   const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      
    //   // 获取容器的宽度和高度
    //   const containerWidth = this.$refs.container.offsetWidth;
    //   const containerHeight = this.$refs.container.offsetHeight;
      
    //   // 判断是否需要添加滚动条
    //   if (containerWidth > windowWidth || containerHeight > windowHeight) {
    //     this.$refs.container.style.overflow = "auto";
    //   } else {
    //     this.$refs.container.style.overflow = "hidden";
    //   }
    // }
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}
#app {
  font-family: "Microsoft YaHei", Helvetica, Arial, sans-serif, "宋体",'xkfont', Avenir, ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.amap-marker-label{
    color: black!important;;
}
.map .amap-marker-label {
    border:1px solid #ccc !important;
}
.el-message p{
  font-size: 1.4rem !important;
}
.el-message .el-message__icon{
  font-size: 1.6rem !important;
}
.dg.a{
  float: left !important;
  margin-left: 20px !important;
  margin-top: 40px !important;
}
.vrModel .el-dialog__body{
  padding-top:20px;
}
</style>
