<template>
    <div class="electronInfo">
        <p>电子化文件</p>
        <el-table :height="height" :data="electronInfo" style="width: 100%">
            <el-table-column prop="name" label="文件名" :width="nameWidth">
            </el-table-column>
            <el-table-column label="操作" :width="caozuoWidth">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="medium">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>

export default {
    props: ['electronInfo'],
    data() {
        return {
            height:window.innerWidth>1366?240:200,
            nameWidth:window.innerWidth>1366?320:260,
            caozuoWidth:window.innerWidth>1366?80:60,
        }
    },
    created() {
    },
    mounted() {
        console.log('表格');
        console.log(this.electronInfo);
    },
    computed: {

    },
    methods: {
        handleClick(row) {
            console.log(row);
            window.open(row.url)
        }
    },
}
</script>
<style lang="scss" scoped>
.electronInfo {
    p {
        text-align: center;
        font-size: 1.2rem;
    }
    ::v-deep .el-table{
        padding-left: 2rem;
    }
}

/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
    background-color: transparent !important;
    color: aliceblue !important;

}

/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
    background-color: transparent !important;
    border: none; //去除表格
    font-size: 1.2rem;
}

/*去除底边框*/
::v-deep.el-table td.el-table__cell {
    border: none;
}

::v-deep.el-table th.el-table__cell.is-leaf {
    border: none;
}

/*去除底部灰条.el-table::before*/
::v-deep .el-table::before {
    display: none;
}
::v-deep .el-table--border th.el-table__cell{
    border-bottom:none !important;
}

// 修改表格文字颜色
::v-deep .el-table thead {
    color: aliceblue;
}
// 滚动条的宽度
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
width: 2px; // 横向滚动条
height: 8px; // 纵向滚动条 必写
}
// 滚动条的滑块
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
background-color: #ccc;
border-radius: 4px;
}
// 滚动条上面的
::v-deep .el-table__fixed-right-patch {
    background-color: transparent !important;
    border-bottom:0 !important;
}

</style>
