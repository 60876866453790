<template>
    <!-- 概览区域 -->
    <div class="panel">
        <div class="inner">
            <h3>{{ homePositionValue.name }}</h3>
            <div class="barChart" ref="barChart"></div> 
        </div>
    </div>
</template>
<script>

export default {
    props:['homePositionValue'],
   data() {
      return {

      }
   },
   created(){
   },
   mounted() {
    this.initChart()
   },
   computed:{
   },
   methods:{
    initChart() {
        if(this.homePositionValue.type === 'pie' ){
            var option = {
                color:['#5A7DD3','#FBCF6C','#FB6272','#A1E289'],
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                    name: `${this.homePositionValue.name}`,
                    type: 'pie',
                    radius: '66%',
                    data: [],
                    label:{
                        show:true,
                        position:'outside',
                        textStyle: {
                            color:'white',
                            fontSize:16,
                            fontFamily:'xkfont',
                        }
                    }
                    }
                ],
            };
            this.homePositionValue.data.forEach((item,index) => {
                if(this.homePositionValue.name === '地理位置'){
                    let objVal = {name:item.region,value :item.num}
                    option.series[0].data.push(objVal)
                }else if(this.homePositionValue.name === '监测系统实施比例'){
                    let objVal = {name:item.installation,value :item.num}
                    option.series[0].data.push(objVal)
                }
                
            })
            console.log(this.homePositionValue);
            console.log('homeValue');
            let chart = this.$echarts.init(this.$refs.barChart)
            chart.setOption(option)
            window.addEventListener("resize", function() {
                chart.resize();
            })
        }else if(this.homePositionValue.type === 'bar'){
            let chart = this.$echarts.init(this.$refs.barChart)
            let optionFontSize = window.innerWidth>1366 ? 12: 8
            // this.$echarts.registerVisual(this.$echarts.util.curry(this.setBarGradient,'#61a0a8'),'bar')
            var option2 = {
                color: new this.$echarts.graphic.LinearGradient(
                    //(x1, y1)点到点(x2, y2)之间进行渐变
                    0, 0, 0, 1, [
                        { offset: 0, color: '#00fffb' }, // 0 起始颜色
                        { offset: 1, color: '#0061ce' }, // 1 结束颜色
                    ]
                ),
                tooltip: {
                    trigger: 'item'
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '6%',
                    top: '7%',
                    // 图表位置紧贴画布边缘是否显示刻度以及label文字 防止坐标轴标签溢出跟grid区域有关系
                    containLabel: true,
                    // 是否显示直角坐标系网格
                    show: true,
                    // grid 四条边框的颜色
                    borderColor: 'rgba(0, 240, 255, 0.3)'
                },
                itemStyle: {
                    // 渐变色从上到下垂直方向
                    gradient: {
                        type:'vertical',
                        colorStops: [{
                            offset:0,
                            color:'#23BFFF'
                        },{
                            offset:1,
                            color:'#D5FFFD'
                        }]
                    }
                },
                // 控制x轴
                xAxis: [{
                    // 使用类目，必须有data属性
                    type: 'category',
                    // 使用data中的数据设为刻度文字
                    data: [],
                    // 刻度设置
                    axisTick: {
                        // true 意思：图形和刻度居中中间
                        //false 意思：图形在刻度之间
                        alignWithLabel: false,
                        // 不显示刻度
                        show: false,
                    },
                    axisLabel: {
                        color: "#4c9bfd",
                        fontSize: optionFontSize,
                        formatter: function(value) {
                        return value.slice(0,2) + '...'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(0, 240, 255, 0.3)'
                                // width:8, x轴线的粗细
                                // opcity：0，如果不想显示x轴线 则改为0
                        }
                    }
                }],
                // 控制y轴
                yAxis: [{
                    type: 'value',
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: "#4c9bfd"
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(0, 240, 255, 0.3)'
                        }
                    },
                    // 控制y轴分割线的颜色样式
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(0, 240, 255, 0.3)'
                        }
                    }
                }],
                series: [{
                    name: '',
                    type: 'bar',
                    barWidth: '60%',
                    data: []
                }]
            }
            console.log(option2);
            this.homePositionValue.data.forEach( item => {
                option2.xAxis[0].data.push(item.cname)
                option2.series[0].data.push(item.num)
            })
            
            chart.setOption(option2)
            window.addEventListener("resize", function() {
                chart.resize();
            })
        }
    },
    // setBarGradient(color, params){
    //     var colorList = [
    //         {
    //             offset:0,
    //             color: color
    //         },
    //         {
    //             offset:1,
    //             color:'#fff'
    //         }
    //     ];
    //     var index = params.dataIndex
    //     var value = params.value
    //     if(value < 0) {
    //         return 
    //     }
    //     return new this.$echarts.LinearGradient(0,0,0,1,colorList)
    // }
    
   },
}
</script>
<style lang="scss" scoped>
.panel {
    position: relative;
    border: 15px solid red;
    border-width: 3rem 2.7rem 2.6rem 4rem;
    border-image-source: url(../assets/border.png);
    border-image-slice: 51 38 20 132;
   //border-image-slice: 40 27 9 121;
    margin-bottom: .20rem;
}

.inner {
    position: absolute;
    top: -3.5rem;
    left: -4rem;
    right: -2.6rem;
    bottom: -2.5rem;
    padding: 1.4rem 1rem;
}

.panel h3 {
    font-family: xkfont;
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
    color: #fff;
    display: flex;
    font-weight: 400;
}
.barChart {
    width: 100%;
    height: 100%;
    
}

</style>
