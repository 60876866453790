<template>
  <div class="echartsList">
    <div class="listChart" ref="listChart"></div>
    <div v-if="isShowINC" class="INCPosition">
    <h5>———面内</h5>
    <h5>- - - - - 面外</h5>
  </div>
  </div>
  
</template>
<script>
import Chart from "echarts";
import { interpolate } from "gsap";
export default {
  props: ["dayMessage", "dayNum", "type", "selectMessage"],
  data() {
    return {
      chart: null,
      isShowINC:false,
    };
  },
  watch: {
    dayMessage(newVal) {
     // console.log("echarts中新的值", newVal);
      this.initCharts();
    },
  },
  mounted() {
    this.initCharts();
    this.chart = this.$echarts.init(this.$refs.listChart);
    this.resizeChart();
    window.addEventListener("resize", this.resizeChart);
  },
  computed: {},
  methods: {
    timestampInSeconds (time,timenum){
      const date = new Date(time * 1000);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const month = date.getMonth()+1;
        const day = date.getDate();

        const paddedHours = String(hours).padStart(2, '0');
        const paddedMinutes = String(minutes).padStart(2, '0');
        const paddedDay = String(day).padStart(2, '0');
        const paddedMonth = String(month).padStart(2, '0');
        let timeData = [paddedMonth,paddedDay,paddedHours,paddedMinutes]
        return timeData 
      
    },
    // 初始化图表
    initCharts() {
    //  console.log("echarts中收到的数据");
      //console.log(this.dayMessage);
      //console.log(this.dayNum);
      //console.log('this.selectMessage',this.selectMessage);
      if(this.selectMessage.selectSensorName == 'inclination') {
        this.isShowINC = true
      }else{
        this.isShowINC = false
      }
      var Color = [
        "#11EE96",
        "#f47920",
        "#FF0033",
        "#d5c59f",
        "#94E61A",
        "#00FFFF",
        "#BB445C",
        "#fcaf17",
        "#ffe600",
        "#CC9966",
        "#90d7ec",
        "#faa755",
      ];

      var XAxias = this.XAxias(this.dayMessage.length);
      // y轴数据
      var SeriesData = [];
      for (let i = 0; i < this.dayNum; i++) {
        let obj = {
          label: {
            show: true,
            textStyle: {
              color: "white",
            },
          },
          name: "INC01",
          type: "line",
          // 设置折线是否平滑
          smooth: true,
          symbol: "none",
          sampling: "lttb",
          lineStyle: {
            normal: {
              color: "#11EE96", // 线条颜色
            },
          },
          //对应x轴的y轴数据
          data: [],
        };
        var i1 = i + 1;
        i1 = i1 < 10 ? "0" + i1 : i1;
        obj.name = "INC" + i1;
        obj.lineStyle.normal.color = Color[i];
        SeriesData.push(obj);
      }
      for (let i = 0; i < this.dayNum; i++) {
        let obj = {
          label: {
            show: true,
            textStyle: {
              color: "white",
            },
          },
          name: "INC01",
          type: "line",
          // 设置折线是否平滑
          smooth: true,
          symbol: "none",
          sampling: "lttb",
          lineStyle: {
            normal: {
              color: "#11EE96", // 线条颜色
              type: "dotted",
            },
          },
          //对应x轴的y轴数据
          data: [],
        };
        var i1 = i + 1;
        i1 = i1 < 10 ? "0" + i1 : i1;
        obj.name = "INC" + i1;
        obj.lineStyle.normal.color = Color[i];
        SeriesData.push(obj);
      }
      var Series;
      if (this.selectMessage.selectSensorName === "inclination") {
        var Data = [
          "INC01",
          "INC02",
          "INC03",
          "INC04",
          "INC05",
          "INC06",
          "INC07",
          "INC08",
          "INC09",
          "INC10",
          "INC11",
          "INC12",
        ];
        var Selected = { INC01: true };
        for (var index = 2; index < this.dayNum + 1; index++) {
          index = index < 10 ? "0" + index : index;
          Selected["INC" + index] = false;
        }
        Series = SeriesData;
      } else {
        Series = [
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT01",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#11EE96", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT02",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#f47920", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT03",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#FF0033", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT04",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#d5c59f", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT05",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#94E61A", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT06",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#00FFFF", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT07",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#BB445C", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT08",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#fcaf17", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT09",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#ffe600", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT10",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#CC9966", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT11",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#90d7ec", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT12",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#faa755", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
        ];
        if (this.selectMessage.selectSensorName === "strain") {
          var Data = [
            "STR01",
            "STR02",
            "STR03",
            "STR04",
            "STR05",
            "STR06",
            "STR07",
            "STR08",
            "STR09",
            "STR10",
            "STR11",
            "STR12",
          ];
          var Selected = { STR01: true };
          for (var index = 2; index < this.dayNum + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["STR" + index] = false;
          }
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "STR" + index;
            return item;
          });
        } else if (this.selectMessage.selectSensorName === "water") {
          var Data = [
            "WAT01",
            "WAT02",
            "WAT03",
            "WAT04",
            "WAT05",
            "WAT06",
            "WAT07",
            "WAT08",
            "WAT09",
            "WAT10",
            "WAT11",
            "WAT12",
          ];
          var Selected = { WAT01: true };
          for (var index = 2; index < this.dayNum + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["WAT" + index] = false;
          }
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "WAT" + index;
            return item;
          });
        } else if (this.selectMessage.selectSensorName === "settlement" ) {
          var Data = [
            "SET01",
            "SET02",
            "SET03",
            "SET04",
            "SET05",
            "SET06",
            "SET07",
            "SET08",
            "SET09",
            "SET10",
            "SET11",
            "SET12",
          ];
          var Selected = { SET01: true };
          for (var index = 2; index < this.dayNum + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["SET" + index] = false;
          }
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "SET" + index;
            return item;
          });
        } else if (this.selectMessage.selectSensorName === "acceleration") {
          var Data = [
            "ACC01",
            "ACC02",
            "ACC03",
            "ACC04",
            "ACC05",
            "ACC06",
            "ACC07",
            "ACC08",
            "ACC09",
            "ACC10",
            "ACC11",
            "ACC12",
          ];
          var Selected = { ACC01: true };
          for (var index = 2; index < this.dayNum + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["ACC" + index] = false;
          }
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "ACC" + index;
            return item;
          });
        } else if (this.selectMessage.selectSensorName === "wind") {
          var Data = [
            "WIN01",
            "WIN02",
            "WIN03",
            "WIN04",
            "WIN05",
            "WIN06",
            "WIN07",
            "WIN08",
            "WIN09",
            "WIN10",
            "WIN11",
            "WIN12",
          ];
          var Selected = { WIN01: true };
          for (var index = 2; index < this.dayNum + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["WIN" + index] = false;
          }
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "WIN" + index;
            return item;
          });
        } else if (this.selectMessage.selectSensorName === "vibration") {
          var Data = [
            "VIB01",
            "VIB02",
            "VIB03",
            "VIB04",
            "VIB05",
            "VIB06",
            "VIB07",
            "VIB08",
            "VIB09",
            "VIB10",
            "VIB11",
            "VIB12",
          ];
          Selected = { VIB01: true };
          for (var index = 2; index < this.dayNum + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["VIB" + index] = false;
          }
          console.log("Series",Series)
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "VIB" + index;
            return item;
          });
        } else if (this.selectMessage.selectSensorName === "temperature") {
          var Data = ["TEM01-温度", "TEM01-湿度", "TEM02-温度", "TEM02-湿度"];
          var Selected = { "TEM01-温度": true, "TEM01-湿度": true,"TEM02-温度": false, "TEM02-湿度": false};
          Series = [
            {
              name: "TEM01-温度",
              type: "line",
              yAxisIndex: 0,
              label: {
                show: true,
                textStyle: {
                  color: "white",
                },
              },
              // 设置折线是否平滑
              smooth: true,
              symbol: "none",
              sampling: "lttb",
              lineStyle: {
                normal: {
                  color: "#11EE96", // 线条颜色
                },
              },
              data: [],
            },
            {
              name: "TEM01-湿度",
              type: "line",
              yAxisIndex: 1,
              label: {
                show: true,
                textStyle: {
                  color: "white",
                },
              },
              // 设置折线是否平滑
              smooth: true,
              symbol: "none",
              sampling: "lttb",
              lineStyle: {
                normal: {
                  color: "#FB6272", // 线条颜色
                  type: "dotted",
                },
              },
              data: [],
            },
            {
              name: "TEM02-温度",
              type: "line",
              yAxisIndex: 0,
              label: {
                show: true,
                textStyle: {
                  color: "white",
                },
              },
              // 设置折线是否平滑
              smooth: true,
              symbol: "none",
              sampling: "lttb",
              lineStyle: {
                normal: {
                  color: "red", // 线条颜色
                },
              },
              data: [],
            },
            {
              name: "TEM02-湿度",
              type: "line",
              yAxisIndex: 1,
              label: {
                show: true,
                textStyle: {
                  color: "white",
                },
              },
              // 设置折线是否平滑
              smooth: true,
              symbol: "none",
              sampling: "lttb",
              lineStyle: {
                normal: {
                  color: "gray", // 线条颜色
                  type: "dotted",
                },
              },
              data: [],
            },
          ];
        } else {
          var Data = [
            "CRA01",
            "CRA02",
            "CRA03",
            "CRA04",
            "CRA05",
            "CRA06",
            "CRA07",
            "CRA08",
            "CRA09",
            "CRA10",
            "CRA11",
            "CRA12",
          ];
          var Selected = { CRA01: true };
          for (var index = 2; index < this.dayNum + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["CRA" + index] = false;
          }
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "CRA" + index;
            return item;
          });
        }
      }
      
      for (var i = 0; i < this.dayMessage.length; i++) {
        var split = this.dayMessage[i].data.split("\t");
        for (var j = 0; j < this.dayNum; j++) {
          if (split[j].search("_") !== -1) {
              var s = split[j].split("_");
              if (s.length > 0) {
               
                if(this.selectMessage.selectSensorHouseId !==8 ){
                  Series[j].data.push(s[0]);
                }else{
                  if(this.selectMessage.selectSensorName !== "temperature"  && this.selectMessage.selectSensorName !== "settlement"){
                     Series[j].data.push(s[0]);
                  }
                }
                if(this.selectMessage.selectSensorName === "settlement" && this.selectMessage.selectSensorHouseId ==8 ){
                  Series[j].data.push(s[1]);  
                }
                if (this.selectMessage.selectSensorName === "inclination") {
                  let i2 = j + this.dayNum;
                  Series[i2].data.push(s[1]);
                } else if (this.selectMessage.selectSensorName === "temperature") {
                  if(this.dayNum==2){
                    
                    Series[2*j].data.push(s[1]); 
                    Series[2*j + 1].data.push(s[0]); 
                  
                  }else{
                    Series[j + 1].data.push(s[1]); //一个测点
                  }
                  
                }
              }
            } else {
              Series[j].data.push(split[j]);
            }
         
        }
      }
      
    //  console.log("Series");
      //console.log(Series);
      let typeName;
      if (this.type === "week") {
        typeName = "每周";
      } else if (this.type === "day") {
        typeName = "每日";
      } else {
        typeName = "每月";
      }
      let optionBottom = this.dayNum > 6 ? 10 : 20;
      let optionLeft = this.dayNum > 6 ? 50 : 30;
      if (this.selectMessage.selectSensorName === "temperature") {
        var interval = this.type === "month" ? 120 : 48;
        var option = {
          animation: false,
          //折线图标题
          title: {
            text: `${this.selectMessage.selectSensor}(${this.selectMessage.selectUnit})/${typeName}`,
            left: "center",
            top: "10",
            textStyle: {
              lineHeight: 30,
              color: "#ffffff",
              fontFamily: "xkfont",
              fontSize: 22,
            },
          },
          // 折线图线条的颜色
          color: Color.slice(0, this.dayNum * 2),
          // 折线图的线条代表意义
          legend: {
            itemWidth: 10, //小圆点的宽度
            itemGap: 6,
            width: 380,
            inactiveColor: "#fff",
            activeColor: "#11EE96",
            textStyle: {
              color: Color.slice(0, this.dayNum * 2),
            },
            selectedModel: "single", //折线可多选
            data: Data.slice(0, this.dayNum * 2),
            selected: Selected,
            bottom: optionBottom,
            left: optionLeft,
            z: 100,
          },
          // 刻度
          grid: {
            left: "3%",
            right: "4%",
            bottom: "20%",
            top: "20%",
            containLabel: true,
            show: true,
            // borderColor: 'rgba(0, 240, 255, 0.3)'
          },
          // 悬浮图标
          tooltip: {
            show: true,
            trigger: "axis",
            //这一步的效果是当你的光标在左边的时候，悬浮标题在右边，在右边的时候，悬浮标题在左边
            position: function (pos, params, dom, rect, size) {
              var obj = { top: 60 };
              obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
              return obj;
            },
          },
          //x坐标轴
          xAxis: {
            type: "category",
            axisLabel: {
              interval: interval,
              color: "#6495ED",
              color: "#ffffff",
              formatter: function(value) {
                // 截取标签的前 5 个字符（例如 '07/15'）
                if(typeName==="每日"){
                  return value.slice(6, 14);
                }else{
                  return value.slice(0, 5);
                }
                
              },
              textStyle: {
                fontSize: 13,
              },
              showMaxLabel: true,
            },
            axisTick: {
              show: true,
              inside: true,
              
            },
            axisLine: {
              lineStyle: {
                color: "#6495ED",
              },
            },
            //x坐标轴
            data: XAxias,
          },
          //y坐标轴
          yAxis: [
            {
              type: "value",
              name: "温度(℃)",
              position: "left",
              color: "#ffffff",
              alignTicksL: true,
              // max:function(value) {return value.max*2},
              // min:function(value) {return value.min*0.5},
              max: 50,
              min: 10,
              nameTextStyle: {
                color: "#ffffff",
              },
              axisLabel: {
                textStyle: {
                  fontSize: 13,
                  color: "#ffffff",
                },
                color: "#6495ED",
                formatter: function (value, index) {
                  return value;
                },
              },
              axisTick: {
                inside: true,
                show: true,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#6495ED",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#6495ED",
                },
              },
            },
            {
              type: "value",
              name: "湿度(%)",
              position: "right",
              alignTicksL: true,
              // max:function(value) {return value.max*2},
              // min:function(value) {return value.min*0.5},
              max: 100,
              min: 20,
              nameTextStyle: {
                color: "#ffffff",
              },
              axisLabel: {
                textStyle: {
                  fontSize: 13,
                  color: "#ffffff",
                },
                color: "#6495ED",
                formatter: function (value, index) {
                  return value;
                },
              },
              axisTick: {
                inside: true,
                show: true,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#6495ED",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#6495ED",
                },
              },
            },
          ],
          dataZoom: [
            {
              type: "slider",
              show: false,
            },
          ],
          // 存放数据
          series: Series.slice(0, this.dayNum * 2),
        };
        let chart = this.$echarts.init(this.$refs.listChart);
        chart.setOption(option, true);
        chart
          .off("legendselectchanged")
          .on("legendselectchanged", function (params) {
            console.log("params");
            console.log(params);
            Selected = params.selected;
            // var name = params.name
            // if(this.Selected[])
            // this.Selected[name] = selected[name]
            option.legend.selected = Selected;
            chart.setOption({
              legend: {
                selected: Selected,
              },
            });
          });
      } else {
       
        var interval = this.type === "month" ? 120 : 48;
        this.selectMessage.selectSensor =
          this.selectMessage.selectSensor === "风速风向"
            ? "风速"
            : this.selectMessage.selectSensor;
        var option = {
          animation: false,
          //折线图标题
          title: {
            text: `${this.selectMessage.selectSensor}(${this.selectMessage.selectUnit})/${typeName}`,
            left: "center",
            top: "10",
            textStyle: {
              lineHeight: 30,
              color: "#ffffff",
              fontFamily: "xkfont",
              fontSize: 22,
            },
          },
          // 折线图线条的颜色
          color: Color.slice(0, this.dayNum),
          // 折线图的线条代表意义
          legend: {
            itemWidth: 10,
            itemGap: 6,
            width: 500,
            inactiveColor: "#fff",
            activeColor: "#11EE96",
            textStyle: {
              color: Color.slice(0, this.dayNum),
            },
            selectedModel: "single", //折线可多选
            data: Data.slice(0, this.dayNum),
            selected: Selected,
            bottom: optionBottom,
            left: optionLeft,
            z: 100,
          },
          // 刻度
          grid: {
            left: "3%",
            right: "8%",
            bottom: "20%",
            top: "20%",
            containLabel: true,
            show: true,
            // borderColor: 'rgba(0, 240, 255, 0.3)'
          },
          // 悬浮图标
          tooltip: {
            show: true,
            trigger: "axis",
            //这一步的效果是当你的光标在左边的时候，悬浮标题在右边，在右边的时候，悬浮标题在左边
            position: function (pos, params, dom, rect, size) {
              var obj = { top: 60 };
              obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
              return obj;
            },
          },
          //x坐标轴
          xAxis: {
            type: "category",
            axisLabel: {
              interval: interval,
              color: "#6495ED",
              color: "#ffffff",
              formatter: function(value) {
                if(typeName==="每日"){
                  return value.slice(6, 14);
                }else{
                  return value.slice(0, 5);
                }
              },  
              textStyle: {
                fontSize: 13,
              },
              showMaxLabel: true,
            },
            axisTick: {
              show: true,
              inside: true,
            },
            axisLine: {
              lineStyle: {
                color: "#6495ED",
              },
            },
            //x坐标轴
            data: XAxias,
          },
          //y坐标轴
          yAxis: {
            axisLabel: {
              textStyle: {
                fontSize: 13,
                color: "#ffffff",
              },
              color: "#6495ED",
              formatter: function (value, index) {
                return value;
              },
            },
            axisTick: {
              inside: true,
              show: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#6495ED",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#6495ED",
              },
            },
            type: "value",
            scale: true,
            // min:function(value){return (value.min-(value.max-value.min)).toFixed(4)},
            // max:function(value){return (value.max+(value.max-value.min)).toFixed(4)}
          },
          dataZoom: [
            {
              type: "slider",
              show: false,
            },
          ],
          // 存放数据
          series: Series.slice(0, this.dayNum * 2),
        };
        let chart = this.$echarts.init(this.$refs.listChart);
        chart.setOption(option, true);
        chart
          .off("legendselectchanged")
          .on("legendselectchanged", function (params) {
            console.log("params");
            console.log(params);
            Selected = params.selected;
            // var name = params.name
            // if(this.Selected[])
            // this.Selected[name] = selected[name]
            option.legend.selected = Selected;
            chart.setOption({
              legend: {
                selected: Selected,
              },
            });
          });
      }
    },
    // 图表的X轴()数据
    XAxias(length) {
      var XAxias = [];
      if (this.type === "day") {
        for(let i = 0; i <length; i++){
          let mytime = this.dayMessage[i]['create_time']
          let echartsTime =  this.timestampInSeconds(mytime,"day")
          XAxias.push(`${echartsTime[0]}/${echartsTime[1]} ${echartsTime[2]}:${echartsTime[3]}`);
        }

      } else if (this.type === "week") {

        for(let i = 0; i <length; i++){
          let mytime = this.dayMessage[i]['create_time']
          let echartsTime =  this.timestampInSeconds(mytime,"week")
          XAxias.push(`${echartsTime[0]}/${echartsTime[1]} ${echartsTime[2]}:${echartsTime[3]}`);
        }
      } else {
        /*
        let nowTime = new Date().getTime();
        for (let i = length; i >= 0; i--) {
          let minc = nowTime - i * 60 * 60000;
          let minDate = new Date(minc);
          let Month = minDate.getMonth() + 1;
          let d =
            minDate.getDate() < 10
              ? "0" + minDate.getDate()
              : minDate.getDate();
          XAxias.push(`${Month}/${d}:1`);
        }*/
        for(let i = 0; i <length; i++){
          let mytime = this.dayMessage[i]['create_time']
          
          let echartsTime =  this.timestampInSeconds(mytime,"month")
         // console.log("mytimemytimemytimemytimemytimemytimemytimemytimemytime",echartsTime)
          XAxias.push(`${echartsTime[0]}/${echartsTime[1]} ${echartsTime[2]}:${echartsTime[3]}`);
        }
      }
      return XAxias;
    },
    resizeChart() {
      this.chart.resize();
    },
  },
};
</script>
<style lang="scss" scoped>
.echartsList {
  width: 100%;
  height: 100%;
  .listChart {
    width: 100%;
    height: 100%;
  }
}
.INCPosition {
     position: absolute;
    top: 5.8%;
    right: 12%;
    line-height: 1rem;
    font-family: Arial, Helvetica, sans-serif;
  }
</style>
